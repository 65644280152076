import { Swiper } from 'swiper/react'
import styled from '@mui/material/styles/styled'

export const GridContainerMegaMenu = styled('div', {
  name: 'MegaMenu',
  slot: 'GridContainer',
})(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

export const GridFirstColumn = styled('div', {
  name: 'MegaMenu',
  slot: 'GridItemFirstColumn',
})(({ theme }) => ({
  overflowY: 'auto',
  flex: 1,
  backgroundColor: theme.palette.background.light.primary,
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(6)} 0 ${theme.spacing(6)} ${theme.spacing(10)}`,
  },
  padding: `${theme.spacing(6)} ${theme.spacing(6)} 0`,
}))

export const GridSecondColumn = styled('div', {
  name: 'MegaMenu',
  slot: 'GridItemSecondColumn',
})(({ theme }) => ({
  overflowY: 'auto',
  flex: 1,
  position: 'relative',
  backgroundColor: theme.palette.background.light.quinary,
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(6),
  },
}))

export const GridThirdColumn = styled('div', {
  name: 'MegaMenu',
  slot: 'GridItemThirdColumn',
})(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.light.primary,
}))

export const ScrollableContentWrapper = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 100,
  background: 'linear-gradient(to bottom, transparent, #f6f6f6 60%)',
  gap: theme.spacing(2),
  button: {
    width: 16,
    color: theme.palette.text.dark.primary,
  },
}))

export const StyledSwiper = styled(Swiper)(() => ({
  width: '100%',
  height: '100%',
  '.swiper-slide': {
    height: 'auto',
  },
}))

export const MenuColumnCtaContainer = styled('div', {
  name: 'MegaMenu',
  slot: 'CtaContainer',
})(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'block',
    marginTop: 'auto',
    position: 'absolute',
    bottom: theme.spacing(6),
    left: theme.spacing(6),
    // solution for having a minimum bottom margin together with 'auto' value
    '& > *:first-of-type': {
      marginTop: theme.spacing(6),
    },
  },
}))
