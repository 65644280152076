import React from 'react'
import { IPlacement, isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import CtaMegaMenu from '../../CtaMegaMenu'
import {
  BottomContentBookAnAppointment,
  CtaBookAnAppointment,
  FormBookAnAppointment,
  IconTextBookAnAppointment,
  TextBookAnAppointment,
  TitleBookAnAppointment,
  TopContentBookAnAppointment,
  WrapperBookAnAppointment,
} from './BookAnAppointmentSearchBox.style'
import { Button, TextField } from '../../UI'
import IconWithText from './components/IconWithText'
import { useTranslation } from 'next-i18next'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import HTMLReactParser from 'html-react-parser'

const BookAnAppointmentSearchBox: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const viewType = placement?.viewtype || ''
  const placementName = placement?.name || ''
  const teaserItem = placement?.items?.find(isLXTeaser)
  const collectionItem = placement?.items?.find(isCMCollection)
  const { t } = useTranslation()

  const title = teaserItem?.teaserTitle1 || ''
  const text = teaserItem?.teaserText1.replace(/<.*?>/gi, '') || ''

  const collectionTitle = collectionItem?.collectionTitle || ''
  const collectionText = collectionItem?.collectionText.replace(/<.*?>/gi, '') || ''
  const collectionTeasableItems = collectionItem?.teasableItems.filter(isLXTeaser) || []
  const collectionLXCallToActionSettings = collectionItem?.teaserLXCallToActionSettings || []

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <WrapperBookAnAppointment {...metaData}>
      <TopContentBookAnAppointment>
        <TitleBookAnAppointment fontSize={20}>{title}</TitleBookAnAppointment>
        <TextBookAnAppointment>{HTMLReactParser(text)}</TextBookAnAppointment>
        <FormBookAnAppointment>
          <TextField
            required
            placeholder="Enter city, province or postal code"
            defaultValue=""
            customInputProps={{
              endAdornment: <SVGIcon library="global" name="locator" size={24} />,
            }}
          />
          <div>
            <Button data-element-id="X_X_MainNav_Search_Open">{t('Header.Actions.Search')}</Button>
          </div>
        </FormBookAnAppointment>
      </TopContentBookAnAppointment>
      <BottomContentBookAnAppointment>
        <TitleBookAnAppointment fontWeight="bold">{collectionTitle}</TitleBookAnAppointment>
        <TextBookAnAppointment>{collectionText}</TextBookAnAppointment>
        <IconTextBookAnAppointment>
          {collectionTeasableItems.map(teasableItem => (
            <React.Fragment key={teasableItem.id}>
              <IconWithText teasableItem={teasableItem} />
            </React.Fragment>
          ))}
        </IconTextBookAnAppointment>
        <CtaBookAnAppointment>
          {collectionLXCallToActionSettings.map((actionSettings, index) => (
            <CtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              dataElementId={`X_X_Book_An_Appointment_Placement_Banner_${actionSettings.callToActionText?.replaceAll(' ', '_')}_CTA${index}`}
              actionSettings={actionSettings}
            />
          ))}
        </CtaBookAnAppointment>
      </BottomContentBookAnAppointment>
    </WrapperBookAnAppointment>
  )
}

export default BookAnAppointmentSearchBox
