import { find } from 'lodash-es'
import React, { CSSProperties } from 'react'
import BannersWithIcons from '../../../../../../../../components/CmsPlacement/BannersWithIcons'
import BigMenuBanner from '../../../../../../../../components/CmsPlacement/BigMenuBanner'
import BookAnAppointmentSearchBox from '../../../../../../../../components/CmsPlacement/BookAnAppointmentSearchBox'
import CategoriesWithIcon from '../../../../../../../../components/CmsPlacement/CategoriesWithIcon'
import GroupOfMenuItems from '../../../../../../../../components/CmsPlacement/GroupOfMenuItems'
import ListOfBrandIcon from '../../../../../../../../components/CmsPlacement/ListOfBrandIcons'
import ListOfBrands from '../../../../../../../../components/CmsPlacement/ListOfBrands'
import ListOfProducts from '../../../../../../../../components/CmsPlacement/ListOfProducts'
import useBreakpoints from '../../../../../../../../hooks/useBreakpoints'
import { IContentMegaMenu } from '../../../../../../../../types/cms'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { GridContainerMegaMenu, GridFirstColumn, GridSecondColumn, GridThirdColumn } from './MegaMenuContent.style'

import ScrollableContainerWithArrows from '../../../../../../../../components/UI/ScrollableContainerWithArrows'
import { PlacementContextWrapper } from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/PlacementContextWrapper'
import { useDynamicCmsContent } from '@foundation/hooks/useDynamicCmsContent'

export interface MegaMenuColumnComponentProps {
  enableScroll: boolean
  scrollGradientColor: CSSProperties['color']
  placement: IPlacement
}

const MegaMenuContent: React.FC<{
  content?: IContentMegaMenu
  isContentVisible: boolean
}> = ({ content, isContentVisible }) => {
  const { isMobile } = useBreakpoints()
  const placements = useDynamicCmsContent(content?.placements ?? [])
  const firstColumn = find(placements, { name: 'header_ddm_first_column' })
  const secondColumn = find(placements, {
    name: 'header_ddm_second_column',
  })
  const thirdColumn = find(placements, { name: 'header_ddm_third_column' })

  const viewtype = (secondColumn && secondColumn['viewtype']) || null
  const enableSecondMenu =
    isMobile && viewtype && ['list-of-products', 'banners-with-icons', 'list-of-brands'].includes(viewtype)

  return (
    <GridContainerMegaMenu>
      <GridFirstColumn id={firstColumn?.name}>
        <MegaMenuPlacement placement={firstColumn} />
      </GridFirstColumn>

      {!enableSecondMenu ? (
        <GridSecondColumn>
          {isContentVisible && (
            <ScrollableContainerWithArrows>
              <MegaMenuPlacement placement={secondColumn} />
            </ScrollableContainerWithArrows>
          )}
        </GridSecondColumn>
      ) : null}
      <GridThirdColumn>
        <MegaMenuPlacement placement={thirdColumn} group={content?.title ?? content?.name} />
      </GridThirdColumn>
    </GridContainerMegaMenu>
  )
}

const MegaMenuPlacement: React.FC<{ placement?: IPlacement; group?: string }> = props => {
  const { placement, group } = props
  const viewType = placement?.viewtype
  let DynamicComponent

  switch (viewType) {
    case 'categories-with-icon':
      DynamicComponent = CategoriesWithIcon
      break
    case 'group-of-menu-items':
      DynamicComponent = GroupOfMenuItems
      break
    case 'big-menu-banner':
      DynamicComponent = BigMenuBanner
      break
    case 'banners-with-icons':
      DynamicComponent = BannersWithIcons
      break
    case 'list-of-products':
      DynamicComponent = ListOfProducts
      break
    case 'list-of-brand-icons':
      DynamicComponent = ListOfBrandIcon
      break
    case 'list-of-brands':
      DynamicComponent = ListOfBrands
      break
    case 'book-an-appointment-plus-search-box':
      DynamicComponent = BookAnAppointmentSearchBox
      break
    default:
      DynamicComponent = null
  }

  return (
    <PlacementContextWrapper initialData={{ placement }}>
      {DynamicComponent && <DynamicComponent placement={placement} group={group} />}
    </PlacementContextWrapper>
  )
}

export default MegaMenuContent
