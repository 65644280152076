import React from 'react'
import { v4 as uuid } from 'uuid'
import { IPlacement, IPlacementItem, isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ICMChannel } from '@typesApp/cmsPlacement/CMChannel'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'
import { ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'

import {
  ContentCtaGroupOfMenuItems,
  ContentItemGroup,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledAccordionWrapper,
  StyledDivider,
  TitleItemGroup,
  WrapperGroupOfMenuItems,
} from './GroupToMenuItems.style'
import { CmsAnchorMegaMenu } from '@components/Cms/CmsComponents-CSS/CmsAnchorMegaMenu'
import useBreakpoints from '../../../hooks/useBreakpoints'
import CtaMegaMenu from '../../CtaMegaMenu'
import { useSelector } from 'react-redux'
import { openDrawerMegaMenuSelector } from '../../../features/ui/selector'
import { camelCase, startCase } from 'lodash-es'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const GroupOfMenuItems: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const { isDesktop } = useBreakpoints()
  const { categoryName } = useSelector(openDrawerMegaMenuSelector)
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const collectionItems = placement?.items?.filter(isCMCollection)
  const teaserItem = placement?.items?.find(isLXTeaser)

  const isDDMFirstColumn = placementName === 'header_ddm_first_column'

  if (!collectionItems) return null
  const teaserLXCallToActionSettings = teaserItem?.teaserLXCallToActionSettings || []
  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  const filterType = (teasableItems: IPlacementItem[]) => {
    const listType = ['CMChannel', 'CMExternalPage', 'CMExternalChannel']
    return (
      (teasableItems.filter(teasableItem => listType.includes(teasableItem.type)) as (
        | ICMChannel
        | ICMExternalChannel
        | ICMExternalPage
      )[]) || []
    )
  }

  const removeAllSpaces = (str: string): string => {
    return str.replaceAll(' ', '')
  }

  const getAnalyticsProps = (
    item: ICMCollection,
    teasableItem: ICMChannel | ICMExternalPage | ICMExternalChannel
  ): { elementId: string | undefined; description: string | undefined } => {
    if (item && item.name && item.name.toLowerCase().includes('brand')) {
      return {
        elementId: 'X_X_MainNav_Brand',
        description: teasableItem.title,
      }
    } else {
      return {
        elementId: [
          'X_X_MainNav',
          removeAllSpaces(startCase(camelCase(`${categoryName}`))),
          removeAllSpaces(startCase(camelCase(`${teasableItem.title}`))),
        ].join('_'),
        description: removeAllSpaces(startCase(camelCase(teasableItem.title))),
      }
    }
  }

  return (
    <WrapperGroupOfMenuItems {...metaData}>
      {collectionItems.map(
        (item: ICMCollection) =>
          (!isDesktop && item.teasableItems.length && !isDDMFirstColumn && (
            <StyledAccordionWrapper key={item?.id ?? uuid()}>
              <StyledAccordion defaultExpanded={false}>
                <StyledAccordionSummary expandIcon={<SVGIcon library="arrow" name="arrow-down" />}>
                  {item.collectionTitle}
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  {filterType(item.teasableItems).map(teasableItem => (
                    <React.Fragment key={teasableItem.id}>
                      <CmsAnchorMegaMenu
                        {...getAnalyticsProps(item, teasableItem)}
                        href={teasableItem.formattedUrl || ''}
                      >
                        {teasableItem.title}
                      </CmsAnchorMegaMenu>
                    </React.Fragment>
                  ))}
                </StyledAccordionDetails>
              </StyledAccordion>
              <StyledDivider />
            </StyledAccordionWrapper>
          )) || (
            <div key={item?.id ?? uuid()}>
              <TitleItemGroup>{item.collectionTitle}</TitleItemGroup>
              <ContentItemGroup>
                {filterType(item.teasableItems).map(teasableItem => (
                  <React.Fragment key={teasableItem.id}>
                    <CmsAnchorMegaMenu
                      {...getAnalyticsProps(item, teasableItem)}
                      href={teasableItem.formattedUrl || ''}
                    >
                      {teasableItem.title}
                    </CmsAnchorMegaMenu>
                  </React.Fragment>
                ))}
              </ContentItemGroup>
            </div>
          )
      )}
      {isDesktop && (
        <ContentCtaGroupOfMenuItems>
          {teaserLXCallToActionSettings.map((actionSettings, index) => (
            <CtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              dataElementId={`X_X_Group_Of_Menu_Items_Placement_Banner_${actionSettings.callToActionText?.replaceAll(
                ' ',
                '_'
              )}_CTA${index}`}
              actionSettings={actionSettings}
            />
          ))}
        </ContentCtaGroupOfMenuItems>
      )}
    </WrapperGroupOfMenuItems>
  )
}

export default GroupOfMenuItems
