import styled from '@mui/material/styles/styled'
import { AccordionDetails } from '@mui/material'
import { Accordion, AccordionSummary } from '../../UI'
import { customPalette } from '../../../themes/color-palette'

export const WrapperGroupOfMenuItems = styled('div', {
  name: 'GroupOfMenuItems',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(6)} ${theme.spacing(6)}`,
  fontSize: 14,
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(6),
  },
}))

export const TitleItemGroup = styled('div', {
  name: 'GroupOfMenuItems',
  slot: 'Title',
})({
  fontWeight: 'bold',
})

export const ContentItemGroup = styled('div', {
  name: 'GroupOfMenuItems',
  slot: 'Content',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: `${theme.spacing(4)} 0`,
  gap: theme.spacing(4),
}))

export const ContentCtaGroupOfMenuItems = styled('div', {
  name: 'GroupOfMenuItems',
  slot: 'Cta',
})(() => ({
  marginTop: 'auto',
}))

const storePalette = customPalette['clearly']
const AccordionConainerWidth = '240px'
const DefaultLineHeight= '21px'

export const StyledAccordionWrapper = styled('div')(() => ({
  color: storePalette.black,
  '& :visited': {
    color: storePalette.black,
  },
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  paddingLeft: theme.spacing(10),
}))

export const StyledAccordionSummary =  styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: 'inherit',
  color: storePalette.black,
  width: AccordionConainerWidth,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: 700,
  lineHeight: DefaultLineHeight,
  zIndex:  22342343,
  borderBottom: theme.spacing(0),
}))

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor:  'inherit',
  color: storePalette.black,
  paddingLeft: theme.spacing(0),
  'div' : {
    paddingBottom: theme.spacing(4),
  },
  '& :hover, & :visited' : {
    color: storePalette.black,
  }
}))

export const StyledDivider = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(10),
  marginBottom: theme.spacing(0),
  width: AccordionConainerWidth,
  height: 1,
  backgroundColor: theme.palette.text.light.tertiary,
}))
